import React from 'react'
import Cart from "../components/CartPage/Cart"
import Nav from '../components/Nav/Nav'

const SingleProduct = () => {
  return (
    <>
    <Cart/>
    </>
  )
}

export default SingleProduct